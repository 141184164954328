import { useTranslation } from "react-i18next"

export const useLinks = () => {
  const { t } = useTranslation()

  const links = [
    [
      {
        text: t('footer.home'),
        url: '/'
      },
      {
        text: t('footer.caseStudy'),
        url: '/case-study'
      },
      {
        text: t('footer.news'),
        url: '/news'
      },
      {
        text: t('footer.contact'),
        url: '/#contact'
      },
      {
        text: t('footer.about'),
        url: '/about'
      }
    ],
    [
      {
        text: 'Facebook',
        url: 'https://www.facebook.com/SwipeStation/',
        isExternal: true
      },
      {
        text: 'Instagram',
        url: 'https://www.instagram.com/swipestation/',
        isExternal: true
      },
      {
        text: 'Twitter',
        url: 'https://twitter.com/SwipeStation',
        isExternal: true
      },
      {
        text: 'LinkedIn',
        url: 'https://www.linkedin.com/company/swipestation-limited',
        isExternal: true
      }
    ],
    [
      {
        text: t('footer.termsAndConditions'),
        url: '/terms-and-conditions'
      }
    ]
  ]

  return links
}

const config = require('../../../config')
const propertiesToUpdate = [
  '_rawDescription',
  'title',
  'buttonText',
  'email',
  'phone',
  'address',
  'ctaLabel',
  'subtitle',
  'category',
  'question',
  '_rawAnswer',
  '_rawBody',
  '_rawExcerpt',
  'name',
  'position',
  'quoteBody',
  'description'
]

const getTranslation = (obj) => {
  const newObj = {}

  propertiesToUpdate.forEach((property) => {
    if (obj.hasOwnProperty(property) && Array.isArray(obj[property])) {
      const language = obj[property].find(
        (item) => item._key === config.site.language
      )
      if (language) {
        newObj[property] = language.value
      }
    }
  })

  return { ...obj, ...newObj }
}

export default getTranslation
